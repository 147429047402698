<template>
    <div>
        <ProductsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Vibration Isolation Curbs</h1>

            <p>CDI's &quot;Iso Curb&quot; incorporates the latest in design and innovation for the HVAC market utilizing adjustable isolators in a single piece curb system. CDI Curb systems may be fully installed and roofed in prior to mounting RTU/AHU minimizing unaccounted labor time and delays with other &quot;field assembly required&quot; curbs on the market. Prime G-90 18ga weather skirting and bulb style weather seal provide a long lasting solution to the cracking and failing of &quot;other&quot; exposed rubber weather seal designs. All curb systems are subjected to SolidWorks Simulation load testing prior to fabrication accommodating RTU/AHU and all accessories weight and center of gravity insuring a minimum 90% isolation efficiency.</p>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/iso-install-section.jpg`" class="img-responsive img_content_right">

            <h3>Standard Construction Details</h3>
            <ol class="list_content">
                <li>Minimum overall height 18&quot;</li>
                <li>2&quot; Spring deflection</li>
                <li>2x4 wood nailer provided</li>
                <li>18-12ga Prime G-90 galvanized steel</li>
                <li>Fully welded and mitered seams and corners</li>
                <li>Weather skirt and bulb seal insure water tight seal</li>
                <li>2&quot; minimum base flange for securing to building</li>
                <li>Acoustically non-conductive materials minimize sound transitions</li>
                <li>Stabilizing Isolators eliminate excess movement</li>
                <li>Removable Access doors for spring isolator field adjustment</li>
                <li>Same great CDI lead times apply</li>
            </ol>

            <h3>Isolator Spec</h3>
            <ol class="list_content">
                <li>Housings Hot Dip Galvanized (HDG)</li>
                <li>Hardware Zinc Plate</li>
                <li>Springs Powder Coated or Zinc Plated</li>
                <li>2&quot; Deflection</li>
                <li>Style K Load Transfer Nut is pinned to stud</li>
                <li>Large diameter springs with O.D. not less than 80% of rated deflection height</li>
                <li>For compact support of heavy loads, some include inner springs</li>
                <li>California Pre-Approved seismic protection OPA-0070</li>
            </ol>

            <h3>Curb Specifications</h3>

            <p>Vibration Isolation curb systems are manufactured of 12-18ga (ASTM A653/653M) prime G90 galvanized steel construction. All corners and seams are mitered and fully welded with internal 12ga/A.I. structural cross and vertical supports when necessary. All curb systems are water tight and plenum applications are air tight utilizing internal flex duct. Top of all Vibration isolation curb systems are level with pitches built into curb when deck slopes. Vibration isolation curb systems are designed to provide a minimum of 90% isolation efficiency with 2&quot; deflection.</p>

            <h3>All Curb System Options</h3>
            <ol class="list_content">
                <li>External vestibules</li>
                <li>Internal pipe chases</li>
                <li>Sloped condenser pans</li>
                <li>Horizontal discharge</li>
                <li>Pitched Base Curb</li>
                <li>Multi section curbs</li>
            </ol>

            <h3>Resources</h3>
            <ul class="list_content">
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/cdi-iso-spec-sheet.pdf`">Spec Sheet</a>
                </li>
                <li>
                    <a :href="`${$cdiVars.BASE_URL}/pdf/cdi-iso-install-guide.pdf`">Installation Guide</a>
                </li>
                <li>
                    <a href="https://www.youtube.com/watch?v=s8iJx7RwqHc">Adjusting Springs Video Tutorial</a>
                </li>
            </ul>
        </main>
    </div>
</template>

<script>
import ProductsNav from '@/components/content/ProductsNav.vue'
export default {
    name: "Isolation",
    components: {
        ProductsNav,
    },
    created() {
        this.$appStore.setTitle(['Vibration Isolation Curbs']);
    },
}
</script>

<style scoped>

</style>